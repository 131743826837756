import React, { useState, useEffect } from "react";
import axios from "axios";

import showDialog from "./showDialog";
import ConnectWallet from "./ConnectWallet";
import { getContractData, getContract } from "./Web3Modal";
import { simpleStore } from "../utils";

import styles from "../styles/Home.module.css";

const contract = getContract();

function Claimer() {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const [wallet, setWallet] = useState({});
  const { address, name, contractWithSigner } = wallet;

  const [minted, setMinted] = useState(false);
  const [status, setStatus] = useState(0);
  const [supply, setSupply] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const { currentStatus, currentSupply } = await getContractData();
        setStatus(currentStatus);
        setSupply(currentSupply);
      } catch (err) {
        showDialog({
          title: "获取合约状态失败",
          body: err.message,
        });
      }
    })();
  }, []);

  useEffect(() => {
    function handler(wallet) {
      setWallet(wallet);
    }
    const unsubscribe = simpleStore.subscribe("wallet", handler);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    (async () => {
      contract.on("Minted", async (event) => {
        const { currentStatus, currentSupply } = await getContractData();
        setStatus(currentStatus);
        setSupply(currentSupply);
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (address) {
          const minted = await contract.minted(address);
          setMinted(minted);
        }
      } catch (error) {
        showDialog({
          title: "获取合约信息失败",
          body: error.message,
        });
      }
    })();
  }, [address]);

  if (!name) {
    return (
      <div style={{ display: "inline-block" }}>
        <ConnectWallet />
        <div style={{ marginTop: 20 }}>当前数量：{supply} / 2000</div>
      </div>
    );
  }

  let claimButton = (
    <button
      className={styles.claimInputButton}
      onClick={async () => {
        if (!link.length) {
          showDialog({
            title: "请输入你的 Yellow Link",
            body: "如果没有 Yellow Link，我们将无法确认你是即刻会员。",
          });
          return;
        }

        if (loading) {
          return;
        }

        setLoading(true);

        try {
          const res = await axios.post("/api/token", {
            yellowLink: link,
            walletAddress: address,
          });
          const { salt, token } = res.data;
          try {
            const tx = await contractWithSigner.claim(salt, token);
            const response = await tx.wait();

            showDialog({
              title: "Mint 成功",
              body: (
                <>
                  恭喜你拥有了 YellowDAO
                  NFT，记得加入「Web3冒险岛」的私密频道，与其他会员一起交流。
                  <a
                    rel="noreferrer"
                    href="https://discord.gg/cfTRnyRUPZ"
                    target="_blank"
                  >
                    点击加入»
                  </a>
                  <br />
                  <br />
                  同时关注 YellowDAO{" "}
                  <a
                    rel="noreferrer"
                    href="https://twitter.com/YellowDAO"
                    target="_blank"
                  >
                    官方 Twitter»
                  </a>
                  ，还可以去 Opensea 查看{" "}
                  <a
                    rel="noreferrer"
                    href="https://opensea.io/account"
                    target="_blank"
                  >
                    你的 YellowDAO NFT»
                  </a>
                  。
                </>
              ),
              type: "success",
            });
            const minted = await contract.minted(address);
            setMinted(minted);
            const { currentStatus, currentSupply } = await getContractData();
            setStatus(currentStatus);
            setSupply(currentSupply);
          } catch (error) {
            const message = error.message;
            showDialog({
              title: "Mint 失败",
              body: message,
            });
          }
        } catch (error) {
          const errorCode = error.response.data.error_code;
          const chineseMessages = {
            ENS_NOT_MATCH: {
              title: "ENS 或 DAS 域名不匹配",
              body: "你当前登录的钱包 ENS 或 DAS 和你输入的 Yellow Link 所填写的 ENS 或 DAS 域名不匹配。请检查钱包是否正确。",
            },
            ENS_INVALID: {
              title: "ENS 或 DAS 域名不合法",
              body: "Yellow Page 中所填写的 ENS 或 DAS 域名不合法。请检查确保你填写的域名已经被注册和解析，并中包含了 .eth 或 .bit 作为结尾。",
            },
            ENS_NOT_FOUND_ON_YELLOW_PAGE: {
              title: "没有在你的 Yellow Page 上面发现 ENS 或 DAS",
              body: "你必须将你当前钱包对应的 ENS 或 DAS 域名填写到你的 Yellow Page 上面才可以通过验证，请添加。",
            },
            UNKNOWN_ERROR: {
              title: "未知报错，请联系官方 Twitter",
              body: "未知报错，请联系官方 Twitter 反馈，谢谢。",
            },
            YELLOW_PAGE_NOT_FOUND: {
              title: "没有发现 Yellow Page",
              body: "请检查 Yellow Link 填写是否正确。",
            },
            ENS_CHANGED: {
              title: "ENS 或 DAS 被修改了",
              body: "检测到 Yellow Link 中填写的 ENS 或 DAS 域名被修改了，请联系官方 Twitter 反馈，谢谢。",
            },
          };
          showDialog(
            chineseMessages[errorCode] || {
              title: "发生了未知错误",
              body: "请刷新页面重试，或者联系官方 Twitter 反馈，谢谢。",
            }
          );
        }

        setLoading(false);
      }}
    >
      {loading ? "Minting..." : "Mint now"}
    </button>
  );

  if (link.trim().length === 0) {
    claimButton = (
      <button className={styles.claimInputButtonDisabled}>请输入地址</button>
    );
  }

  if (status === 0) {
    claimButton = (
      <button className={styles.claimInputButtonDisabled}>尚未开始</button>
    );
  }

  if (status === 3) {
    claimButton = (
      <button className={styles.claimInputButtonDisabled}>已经结束了</button>
    );
  }

  if (supply >= 2000) {
    claimButton = (
      <button className={styles.claimInputButtonDisabled}>Sold Out</button>
    );
  }

  if (minted) {
    claimButton = (
      <button className={styles.claimInputButtonDisabled}>Minted</button>
    );
  }

  return (
    <>
      <div className={styles.claimInput}>
        <div style={{ display: "flex", marginRight: 10 }}>
          <span>jike.city/</span>
          <input
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            placeholder="your link"
          />
        </div>
        {claimButton}
      </div>
      <div style={{ marginTop: 20 }}>当前数量：{supply} / 2000</div>
    </>
  );
}

export default Claimer;
