function formatName(address) {
  if (address.includes("eth")) {
    return address;
  }
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4
  )}`;
}

function SimpleStore() {
  let store = {};
  let subscriptions = {};

  function get(name) {
    return store[name];
  }

  function set(name, value) {
    store = { ...store, [name]: value };
    if (subscriptions[name] && subscriptions[name].length) {
      subscriptions[name]
        .filter((callback) => callback !== null)
        .forEach((callback) => {
          callback(value);
        });
    }
  }

  function subscribe(name, callback) {
    if (!subscriptions[name]) {
      subscriptions[name] = [];
    }

    const existing = subscriptions[name].find((cb) => cb === callback);
    if (existing) {
      return;
    }

    const length = subscriptions[name].push(callback);
    const index = length - 1;

    return () => {
      subscriptions[name][index] = null;
    };
  }
  return { get, set, subscribe };
}
const simpleStore = SimpleStore();

export { formatName, simpleStore };
