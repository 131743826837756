import Web3Modal from "web3modal";
import Web3 from "web3";
import { providers, ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

import showDialog from "./showDialog";

import RinkebyContract from "../abi/rinkeby.json";
import MainnetContract from "../abi/mainnet.json";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID,
    },
  },
};

const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID;
const NETWORK = CHAIN_ID === "4" ? "rinkeby" : "mainnet";
const Contract = CHAIN_ID === "4" ? RinkebyContract : MainnetContract;

let web3Modal;
if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    network: NETWORK,
    cacheProvider: true,
    providerOptions,
  });
}

async function lookupAddress(address) {
  const provider = await web3Modal.connect();
  const web3Provider = new providers.Web3Provider(provider);
  const ensName = await web3Provider.lookupAddress(address);

  return ensName;
}

async function connectAndGetInfo() {
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  const firstAccount = await web3.eth.getAccounts().then((data) => data[0]);

  const web3Provider = new providers.Web3Provider(provider);
  const signer = web3Provider.getSigner();
  // const address = await signer.getAddress();
  const network = await web3.eth.net.getNetworkType();
  const ensName = await lookupAddress(firstAccount);

  const contract = getContract();
  const contractWithSigner = contract.connect(signer);

  return {
    network: network || "unknown",
    name: ensName || firstAccount,
    address: firstAccount,
    contractWithSigner,
    provider,
  };
}

const contract = new ethers.Contract(
  Contract.contracts.YellowDAO.address,
  Contract.contracts.YellowDAO.abi,
  ethers.getDefaultProvider(NETWORK)
);

function getContract() {
  return contract;
}

async function getContractData() {
  try {
    const contract = getContract();
    const currentStatus = await contract.status();
    const totalSupplyBN = await contract.totalSupply();
    const currentSupply = parseInt(totalSupplyBN.toString());

    return {
      currentStatus,
      currentSupply,
    };
  } catch (error) {
    showDialog({
      title: "获取合约信息失败",
      body: error.message,
    });
  }
}

async function disconnect() {
  await web3Modal.clearCachedProvider();
}

export {
  connectAndGetInfo,
  disconnect,
  getContract,
  getContractData,
  lookupAddress,
};

export default web3Modal;
