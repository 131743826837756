import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import showDialog from "./showDialog";
import { connectAndGetInfo, disconnect, lookupAddress } from "./Web3Modal";

import { formatName, simpleStore } from "../utils";
import styles from "../styles/Home.module.css";

const networkMap = {
  1: "main",
  4: "rinkeby",
};
const CHAIN_ID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID);
const supportedNetwork = networkMap[CHAIN_ID];

function ConnectWallet() {
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({});
  const { address, name } = wallet;

  useEffect(() => {
    function handler(wallet) {
      setWallet(wallet);
    }
    const unsubscribe = simpleStore.subscribe("wallet", handler);
    return () => {
      unsubscribe();
    };
  }, []);

  async function disconnectHandler() {
    setWallet({});
    await disconnect();
    simpleStore.set("wallet", {});
  }

  async function accountChange() {
    showDialog({
      title: "链接状态发生改变，请重新链接钱包",
    });
    setWallet({});
    await disconnect();
  }

  function updateWallet(data) {
    setWallet(data);
    simpleStore.set("wallet", data);
  }

  if (!address) {
    return (
      <div className={styles.connectButtonWrapper}>
        <button
          className={styles.connectButton}
          onClick={async () => {
            if (loading) return;
            setLoading(true);

            try {
              const { address, name, network, contractWithSigner, provider } =
                await connectAndGetInfo();

              provider.on("accountsChanged", async (accounts) => {
                // disconnected all
                if (accounts.length === 0) {
                  await disconnectHandler();
                } else {
                  const address = accounts[0];
                  const ensName = await lookupAddress(address);
                  updateWallet({
                    name: ensName || address,
                    address,
                    contractWithSigner,
                  });
                }
              });
              provider.on("chainChanged", async (chainId) => {
                if (parseInt(chainId.toString()) !== CHAIN_ID) {
                  showDialog({
                    title: "当前网络不支持，请切换",
                    body: `请切换至 ${supportedNetwork}`,
                  });
                }
              });
              provider.on("disconnect", async (error) => {
                await disconnectHandler();
              });

              if (network !== supportedNetwork) {
                showDialog({
                  title: "当前网络不支持，请切换",
                  body: `请切换至 ${supportedNetwork}`,
                });
                setLoading(false);
                return;
              }

              updateWallet({
                name,
                address,
                contractWithSigner,
              });
            } catch (err) {
              showDialog({
                title: "链接钱包失败",
                body: err.message || err,
              });
            }

            setLoading(false);
          }}
        >
          {loading ? "Connecting..." : "Connect Wallet"}
        </button>
      </div>
    );
  }

  return (
    <div
      onClick={async () => {
        await disconnectHandler();
      }}
      className={styles.connectButtonWrapper}
    >
      <button className={styles.connectButton}>{formatName(name)}</button>
      <div
        style={{
          padding: "0 15px 0 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CloseIcon sx={{ color: "#fff" }}></CloseIcon>
      </div>
    </div>
  );
}

export default ConnectWallet;
