/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";
import Head from "next/head";

import styles from "../styles/Home.module.css";

import Card from "../components/Card";
import ConnectWallet from "../components/ConnectWallet";
import Claimer from "../components/Claimer";

function ClaimSection() {
  return (
    <div className={styles.section2}>
      <h1 className={styles.title}>即刻加入 YellowDAO</h1>
      <div className={styles.claim}>
        <div className={styles.claimSteps}>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIndex}>1</div>
              <div className={styles.stepTitle}>
                开通即刻会员并绑定 ENS 或 DAS
              </div>
            </div>
            <div className={styles.stepDetail}>
              下载即刻 app 最新版并开通会员(Jike Yellow)，在你的 Yellow
              Page「社交媒体」中绑定一个 ENS 或 DAS 域名（注意：请不要绑定多个
              ENS 或 DAS，同时 Yellow Page 更新需要审核，请耐心等待）。
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIndex}>2</div>
              <div className={styles.stepTitle}>
                输入你的 Jike.city 域名后缀
              </div>
            </div>
            <div className={styles.stepDetail}>
              连接以太坊钱包后，在下方输入你的 Jike.city
              自定义域名后缀，即可领取你的专属 YellowDAO NFT。NFT
              是免费的，你仅需支付 gas fee。
              <div style={{ marginTop: 20 }}>
                <Claimer />
              </div>
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepHeader}>
              <div className={styles.stepIndex}>3</div>
              <div className={styles.stepTitle}>加入 YellowDAO 社区</div>
            </div>
            <div className={styles.stepDetail}>
              YellowDAO NFT
              持有者，在验证钱包后，可加入「Web3冒险岛」的私密频道，与其他会员一起交流。
              <a
                rel="noreferrer"
                href="https://discord.gg/cfTRnyRUPZ"
                target="_blank"
              >
                点击加入»
              </a>
              <br />
              <br />
              关注 YellowDAO{" "}
              <a
                rel="noreferrer"
                href="https://twitter.com/YellowDAO"
                target="_blank"
              >
                官方 Twitter»
              </a>
              ，去 Opensea 查看{" "}
              <a
                rel="noreferrer"
                href="https://opensea.io/account"
                target="_blank"
              >
                你的 YellowDAO NFT»
              </a>
              。
            </div>
          </div>
        </div>

        <Card />
      </div>
    </div>
  );
}

function Header(props) {
  return (
    <div className={styles.head}>
      <div className={styles.imglogo}>
        <img src="/images/dark-logo.png" alt="" />
      </div>

      <div>
        <ConnectWallet />
      </div>
    </div>
  );
}

function YellowDAO() {
  return (
    <span style={{ background: "#1d1b1c", padding: "5px 10px" }}>
      <span className={styles.yellowDAO}>YellowDAO</span>
    </span>
  );
}

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.social}>
        <a
          href="https://discord.gg/cfTRnyRUPZ"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/discord.png" alt="" />
        </a>
        <a
          href="https://twitter.com/YellowDAO"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/twitter.png" alt="" />
        </a>
        <a
          href="https://opensea.io/collection/yellowdao"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/opensea.png" alt="" />
        </a>
      </div>
      <div style={{ marginTop: 20, color: "#999" }}>确保 Web3 发生在即刻</div>
      <div style={{ marginTop: 20, color: "#999" }}>
        *声明：Yellow NFT 使用的设计素材来自网络，详情请
        <a
          href="https://twitter.com/YellowDAO/status/1479382366460125185"
          target="_blank"
          rel="noreferrer"
        >
          查看本条 Twitter»
        </a>
      </div>
    </div>
  );
}

function Introduction() {
  return (
    <div
      style={{
        position: "relative",
        boxShadow: " 0 2px 2px rgba(29, 27, 28, .2)",
      }}
    >
      <div className={styles.container}>
        <div className={styles.section}>
          <h1 className={styles.title}>
            什么是 <YellowDAO />
          </h1>
          <div className={styles.desc} style={{ marginTop: 60 }}>
            一个由即刻用户自发组织的 DAO <br />
            确保 Web3 发生在即刻
          </div>
          <div className={styles.extraDesc} style={{ marginTop: 20 }}>
            *声明：YellowDAO 由社区发起，与即刻官方无关
          </div>
          <div className={styles.socialLinks}>
            <a
              href="https://discord.gg/cfTRnyRUPZ"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/discord.png" alt="" />
            </a>
            <a
              href="https://twitter.com/YellowDAO"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/twitter.png" alt="" />
            </a>
            <a
              href="https://opensea.io/collection/yellowdao"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/opensea.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div>
      <Head>
        <title>YellowDAO - 确保 Web3 发生在即刻</title>
        <meta name="description" content="YellowDAO：确保 Web3 发生在即刻" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <div className={styles.container}>
          <Header />
        </div>

        <Introduction />

        <div style={{ background: "#faba2f" }}>
          <div className={styles.container}>
            <ClaimSection />
          </div>
        </div>

        <Footer />
      </main>
    </div>
  );
}
