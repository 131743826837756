import React, { useState, useEffect } from "react";
import Tilt from "react-parallax-tilt";

import styles from "../styles/Home.module.css";

function Card() {
  return (
    <div className={styles.cardWrapper}>
      <Tilt
        className={styles.parallax}
        scale={1.1}
        transitionSpeed={2000}
        perspective={500}
      >
        <div className={styles.nftCard}>
          <div className={styles.nftCardText}>Yellow</div>

          <img
            className={styles.nftCardIcon}
            src="/images/image-logo.png"
            alt=""
          />

          <img className={styles.nftCardBgImg} src="/images/card.png" alt="" />
        </div>
      </Tilt>
    </div>
  );
}

export default Card;
