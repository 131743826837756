import React from "react";
import ReactDOM from "react-dom";

import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DialogWrapper = styled.div`
  padding: 40px;
  width: 100%;
  max-width: 450px;
`;

const DialogTitle = styled.div`
  margin-top: 14px;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
`;

const DialogBody = styled.div`
  margin-top: 10px;
  color: #00000073;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  a {
    border-bottom: 1px solid;
  }
`;

function SimpleDialog(props) {
  const { onClose, visible } = props;

  return (
    <Dialog onClose={onClose} open={visible}>
      <DialogWrapper>
        <IconWrapper>
          {props.type === "success" ? (
            <CheckCircleIcon
              sx={{
                fontSize: "60px",
              }}
              color="success"
            ></CheckCircleIcon>
          ) : (
            <ErrorIcon
              sx={{
                fontSize: "60px",
              }}
              color="error"
            ></ErrorIcon>
          )}
        </IconWrapper>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogBody>{props.body}</DialogBody>
      </DialogWrapper>
    </Dialog>
  );
}

function showDialog(options) {
  if (!process.browser) {
    return;
  }
  const { title, body, type } = options;
  const container = document.createDocumentFragment();

  function render({ visible }) {
    ReactDOM.render(
      <SimpleDialog
        title={title}
        visible={visible}
        body={body}
        type={type}
        onClose={close}
      />,
      container
    );
  }

  function close() {
    render({ visible: false });
  }

  render({ visible: true });
}

export default showDialog;
